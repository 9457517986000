export enum ROUTES {
  AllItems = 'items?',
  AllCities = 'master/location/cities',
  AllStates = 'master/location/states',
  AllCategories = 'master/category',
  Faqs = 'faqs/',
  Department = 'articles/',
  SendOtp = 'user/send-otp',
  VerifyOtp = 'user/login',
  FindACondition = 'master/condition/',
  Testimonials = 'testimonials',
  Enquire = 'enquire',
  Profile = 'uhid-profile',
  NearestCentre = 'center',
  Cart = 'carts',
  Address = 'uhid-profile/address',
  DynamicRosterToken = 'https://pulse.apollodiagnostics.in/roster-management/api/auth/token',
  CheckServiceability = 'https://pulse.apollodiagnostics.in/roster-management/api/check-serviceability',
  Slots = 'https://pulse.apollodiagnostics.in/roster-management/api/slot/get-available-slots',
  BookAppointment = 'slots',
  GetHomeCollectionCharges = 'get-charge-detail',
  Banner = 'banner',
  RescheduleHomeCollection = 'home-collection/reschedule',
  CancelBooking = 'home-collection/cancel',
  GetHCCreditPoints = 'hc-credit',
  MyOrdersHistory = 'home-collection',
  PaymentHomeCollection = 'order-payment/initiate',
  SendOtpForReport = 'it-dose/send-otp',
  VerifyOtpForReport = 'it-dose/verify-otp',
  DownloadReport = 'it-dose/download-report',
  UploadFile = 'upload/files',
  Prescription = 'prescription/',
  ValidateCoupon = 'order-payment/validate-coupon',
  GlobalDiscount = 'items/discounts',
}
